import { __decorate, __metadata } from 'tslib';
import { EventEmitter, Component, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, NgZone, NgModule } from '@angular/core';
import { defineCustomElements } from '@siigo/siigo-modal-comments-molecule/loader';

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/** @type {?} */
var proxyInputs = (/**
 * @param {?} Cmp
 * @param {?} inputs
 * @return {?}
 */
function (Cmp, inputs) {
    /** @type {?} */
    var Prototype = Cmp.prototype;
    inputs.forEach((/**
     * @param {?} item
     * @return {?}
     */
    function (item) {
        Object.defineProperty(Prototype, item, {
            get: /**
             * @return {?}
             */
            function () {
                return this.el[item];
            },
            set: /**
             * @param {?} val
             * @return {?}
             */
            function (val) {
                var _this = this;
                this.z.runOutsideAngular((/**
                 * @return {?}
                 */
                function () { return (_this.el[item] = val); }));
            },
        });
    }));
});
/** @type {?} */
var proxyMethods = (/**
 * @param {?} Cmp
 * @param {?} methods
 * @return {?}
 */
function (Cmp, methods) {
    /** @type {?} */
    var Prototype = Cmp.prototype;
    methods.forEach((/**
     * @param {?} methodName
     * @return {?}
     */
    function (methodName) {
        Prototype[methodName] = (/**
         * @return {?}
         */
        function () {
            var _this = this;
            /** @type {?} */
            var args = arguments;
            return this.z.runOutsideAngular((/**
             * @return {?}
             */
            function () { return _this.el[methodName].apply(_this.el, args); }));
        });
    }));
});
/** @type {?} */
var proxyOutputs = (/**
 * @param {?} instance
 * @param {?} el
 * @param {?} events
 * @return {?}
 */
function (instance, el, events) {
    // events.forEach((eventName) => (instance[eventName] = new EventEmitter()));
    events.forEach((/**
     * @param {?} eventName
     * @return {?}
     */
    function (eventName) { return (instance[eventName] = new EventEmitter()); }));
});
// tslint:disable-next-line: only-arrow-functions
/**
 * @param {?} opts
 * @return {?}
 */
function ProxyCmp(opts) {
    /** @type {?} */
    var decorator = (/**
     * @param {?} cls
     * @return {?}
     */
    function (cls) {
        if (opts.inputs) {
            proxyInputs(cls, opts.inputs);
        }
        if (opts.methods) {
            proxyMethods(cls, opts.methods);
        }
        return cls;
    });
    return decorator;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
var SiigoModalCommentsMolecule = /** @class */ (function () {
    function SiigoModalCommentsMolecule(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['primaryButtonTextComments', 'secondaryButtonTextComments', 'hideCommentsModal']);
    }
    SiigoModalCommentsMolecule.decorators = [
        { type: Component, args: [{
                    selector: 'siigo-modal-comments-molecule',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    inputs: ['data', 'dataTest', 'isOpenCommentsModal', 'keyId', 'modalHeaderTitle', 'primaryButtonText', 'secondaryButtonText', 'showCloseButton', 'stopRegularClosing', 'userData', 'valueTextArea', 'width'],
                    outputs: ['primaryButtonTextComments', 'secondaryButtonTextComments', 'hideCommentsModal']
                },] },
    ];
    /** @nocollapse */
    SiigoModalCommentsMolecule.ctorParameters = function () { return [
        { type: ChangeDetectorRef },
        { type: ElementRef },
        { type: NgZone }
    ]; };
    SiigoModalCommentsMolecule = __decorate([
        ProxyCmp({
            inputs: ['data', 'dataTest', 'isOpenCommentsModal', 'keyId', 'modalHeaderTitle', 'primaryButtonText', 'secondaryButtonText', 'showCloseButton', 'stopRegularClosing', 'userData', 'valueTextArea', 'width'],
            methods: ['toggleCommentsModal', 'openCommentsModal', 'closeCommentsModal']
        }),
        __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
    ], SiigoModalCommentsMolecule);
    return SiigoModalCommentsMolecule;
}());

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
defineCustomElements(window);
/** @type {?} */
var DECLARATIONS = [
    // proxies
    SiigoModalCommentsMolecule,
];
var SiigoModalCommentsMoleculeLibraryModule = /** @class */ (function () {
    function SiigoModalCommentsMoleculeLibraryModule() {
    }
    SiigoModalCommentsMoleculeLibraryModule.decorators = [
        { type: NgModule, args: [{
                    declarations: DECLARATIONS,
                    exports: DECLARATIONS,
                    imports: [],
                    providers: [],
                },] },
    ];
    return SiigoModalCommentsMoleculeLibraryModule;
}());

export { SiigoModalCommentsMolecule, SiigoModalCommentsMoleculeLibraryModule, ProxyCmp as ɵa };
