import { __decorate, __metadata } from 'tslib';
import { EventEmitter, Component, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, NgZone, NgModule } from '@angular/core';
import { defineCustomElements } from '@siigo/siigo-input-atom/loader';

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/** @type {?} */
var proxyInputs = (/**
 * @param {?} Cmp
 * @param {?} inputs
 * @return {?}
 */
function (Cmp, inputs) {
    /** @type {?} */
    var Prototype = Cmp.prototype;
    inputs.forEach((/**
     * @param {?} item
     * @return {?}
     */
    function (item) {
        Object.defineProperty(Prototype, item, {
            get: /**
             * @return {?}
             */
            function () {
                return this.el[item];
            },
            set: /**
             * @param {?} val
             * @return {?}
             */
            function (val) {
                var _this = this;
                this.z.runOutsideAngular((/**
                 * @return {?}
                 */
                function () { return (_this.el[item] = val); }));
            },
        });
    }));
});
/** @type {?} */
var proxyMethods = (/**
 * @param {?} Cmp
 * @param {?} methods
 * @return {?}
 */
function (Cmp, methods) {
    /** @type {?} */
    var Prototype = Cmp.prototype;
    methods.forEach((/**
     * @param {?} methodName
     * @return {?}
     */
    function (methodName) {
        Prototype[methodName] = (/**
         * @return {?}
         */
        function () {
            var _this = this;
            /** @type {?} */
            var args = arguments;
            return this.z.runOutsideAngular((/**
             * @return {?}
             */
            function () { return _this.el[methodName].apply(_this.el, args); }));
        });
    }));
});
/** @type {?} */
var proxyOutputs = (/**
 * @param {?} instance
 * @param {?} el
 * @param {?} events
 * @return {?}
 */
function (instance, el, events) {
    // events.forEach((eventName) => (instance[eventName] = new EventEmitter()));
    events.forEach((/**
     * @param {?} eventName
     * @return {?}
     */
    function (eventName) { return (instance[eventName] = new EventEmitter()); }));
});
// tslint:disable-next-line: only-arrow-functions
/**
 * @param {?} opts
 * @return {?}
 */
function ProxyCmp(opts) {
    /** @type {?} */
    var decorator = (/**
     * @param {?} cls
     * @return {?}
     */
    function (cls) {
        if (opts.inputs) {
            proxyInputs(cls, opts.inputs);
        }
        if (opts.methods) {
            proxyMethods(cls, opts.methods);
        }
        return cls;
    });
    return decorator;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
var SiigoInputAtom = /** @class */ (function () {
    function SiigoInputAtom(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['focus', 'blur', 'input', 'change', 'changeInput', 'keydown', 'keyup', 'pressCleanButton']);
    }
    SiigoInputAtom.decorators = [
        { type: Component, args: [{
                    selector: 'siigo-input-atom',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    inputs: ['align', 'autocomplete', 'autofocus', 'cleanButton', 'countWhitDecimal', 'dataTest', 'decimalDigit', 'decimalSeparator', 'disabled', 'errorMessage', 'hiddenHint', 'hint', 'id', 'keyId', 'label', 'maxLength', 'minLength', 'name', 'pattern', 'placeholder', 'prefixText', 'prefixType', 'readonly', 'required', 'requiredMessage', 'sufixText', 'sufixType', 'thousandsSeparator', 'type', 'validField', 'value'],
                    outputs: ['focus', 'blur', 'input', 'change', 'changeInput', 'keydown', 'keyup', 'pressCleanButton']
                },] },
    ];
    /** @nocollapse */
    SiigoInputAtom.ctorParameters = function () { return [
        { type: ChangeDetectorRef },
        { type: ElementRef },
        { type: NgZone }
    ]; };
    SiigoInputAtom = __decorate([
        ProxyCmp({
            inputs: ['align', 'autocomplete', 'autofocus', 'cleanButton', 'countWhitDecimal', 'dataTest', 'decimalDigit', 'decimalSeparator', 'disabled', 'errorMessage', 'hiddenHint', 'hint', 'id', 'keyId', 'label', 'maxLength', 'minLength', 'name', 'pattern', 'placeholder', 'prefixText', 'prefixType', 'readonly', 'required', 'requiredMessage', 'sufixText', 'sufixType', 'thousandsSeparator', 'type', 'validField', 'value'],
            methods: ['setValidator', 'checkValidity', 'validate', 'getCurrentHint', 'resetInput', 'setErrorInput']
        }),
        __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
    ], SiigoInputAtom);
    return SiigoInputAtom;
}());
var SiigoInputHint = /** @class */ (function () {
    function SiigoInputHint(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
    SiigoInputHint.decorators = [
        { type: Component, args: [{
                    selector: 'siigo-input-hint',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    inputs: ['hint']
                },] },
    ];
    /** @nocollapse */
    SiigoInputHint.ctorParameters = function () { return [
        { type: ChangeDetectorRef },
        { type: ElementRef },
        { type: NgZone }
    ]; };
    SiigoInputHint = __decorate([
        ProxyCmp({
            inputs: ['hint']
        }),
        __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
    ], SiigoInputHint);
    return SiigoInputHint;
}());

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
defineCustomElements(window);
/** @type {?} */
var DECLARATIONS = [
    // proxies
    SiigoInputAtom,
    SiigoInputHint
];
var SiigoInputAtomLibraryModule = /** @class */ (function () {
    function SiigoInputAtomLibraryModule() {
    }
    SiigoInputAtomLibraryModule.decorators = [
        { type: NgModule, args: [{
                    declarations: DECLARATIONS,
                    exports: DECLARATIONS,
                    imports: [],
                    providers: [],
                },] },
    ];
    return SiigoInputAtomLibraryModule;
}());

export { SiigoInputAtom, SiigoInputAtomLibraryModule, SiigoInputHint, ProxyCmp as ɵa };
