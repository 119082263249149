import { __decorate, __metadata } from 'tslib';
import { EventEmitter, Component, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, NgZone, NgModule } from '@angular/core';
import { defineCustomElements } from '@siigo/siigo-data-table-molecule/loader';

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
/** @type {?} */
var proxyInputs = (/**
 * @param {?} Cmp
 * @param {?} inputs
 * @return {?}
 */
function (Cmp, inputs) {
    /** @type {?} */
    var Prototype = Cmp.prototype;
    inputs.forEach((/**
     * @param {?} item
     * @return {?}
     */
    function (item) {
        Object.defineProperty(Prototype, item, {
            get: /**
             * @return {?}
             */
            function () {
                return this.el[item];
            },
            set: /**
             * @param {?} val
             * @return {?}
             */
            function (val) {
                var _this = this;
                this.z.runOutsideAngular((/**
                 * @return {?}
                 */
                function () { return (_this.el[item] = val); }));
            },
        });
    }));
});
/** @type {?} */
var proxyMethods = (/**
 * @param {?} Cmp
 * @param {?} methods
 * @return {?}
 */
function (Cmp, methods) {
    /** @type {?} */
    var Prototype = Cmp.prototype;
    methods.forEach((/**
     * @param {?} methodName
     * @return {?}
     */
    function (methodName) {
        Prototype[methodName] = (/**
         * @return {?}
         */
        function () {
            var _this = this;
            /** @type {?} */
            var args = arguments;
            return this.z.runOutsideAngular((/**
             * @return {?}
             */
            function () { return _this.el[methodName].apply(_this.el, args); }));
        });
    }));
});
/** @type {?} */
var proxyOutputs = (/**
 * @param {?} instance
 * @param {?} el
 * @param {?} events
 * @return {?}
 */
function (instance, el, events) {
    // events.forEach((eventName) => (instance[eventName] = new EventEmitter()));
    events.forEach((/**
     * @param {?} eventName
     * @return {?}
     */
    function (eventName) { return (instance[eventName] = new EventEmitter()); }));
});
// tslint:disable-next-line: only-arrow-functions
/**
 * @param {?} opts
 * @return {?}
 */
function ProxyCmp(opts) {
    /** @type {?} */
    var decorator = (/**
     * @param {?} cls
     * @return {?}
     */
    function (cls) {
        if (opts.inputs) {
            proxyInputs(cls, opts.inputs);
        }
        if (opts.methods) {
            proxyMethods(cls, opts.methods);
        }
        return cls;
    });
    return decorator;
}

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
var SiigoDataTableCell = /** @class */ (function () {
    function SiigoDataTableCell(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
    SiigoDataTableCell.decorators = [
        { type: Component, args: [{
                    selector: 'siigo-data-table-cell',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    inputs: ['align', 'colKey', 'item', 'rowId', 'template']
                },] },
    ];
    /** @nocollapse */
    SiigoDataTableCell.ctorParameters = function () { return [
        { type: ChangeDetectorRef },
        { type: ElementRef },
        { type: NgZone }
    ]; };
    SiigoDataTableCell = __decorate([
        ProxyCmp({
            inputs: ['align', 'colKey', 'item', 'rowId', 'template'],
            methods: ['updateTemplate']
        }),
        __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
    ], SiigoDataTableCell);
    return SiigoDataTableCell;
}());
var SiigoDataTableHeaderButton = /** @class */ (function () {
    function SiigoDataTableHeaderButton(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['sortData']);
    }
    SiigoDataTableHeaderButton.decorators = [
        { type: Component, args: [{
                    selector: 'siigo-data-table-header-button',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    inputs: ['align', 'child', 'keyId', 'order', 'resizeColumn', 'selected', 'sortable', 'text', 'tooltip', 'type'],
                    outputs: ['sortData']
                },] },
    ];
    /** @nocollapse */
    SiigoDataTableHeaderButton.ctorParameters = function () { return [
        { type: ChangeDetectorRef },
        { type: ElementRef },
        { type: NgZone }
    ]; };
    SiigoDataTableHeaderButton = __decorate([
        ProxyCmp({
            inputs: ['align', 'child', 'keyId', 'order', 'resizeColumn', 'selected', 'sortable', 'text', 'tooltip', 'type']
        }),
        __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
    ], SiigoDataTableHeaderButton);
    return SiigoDataTableHeaderButton;
}());
var SiigoDataTableMolecule = /** @class */ (function () {
    function SiigoDataTableMolecule(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['init', 'change', 'changeRow', 'openEditMode', 'closeEditMode', 'checkboxTableHeader', 'buttonEmptyData', 'buttonDropdownEmptyData']);
    }
    SiigoDataTableMolecule.decorators = [
        { type: Component, args: [{
                    selector: 'siigo-data-table-molecule',
                    changeDetection: ChangeDetectionStrategy.OnPush,
                    template: '<ng-content></ng-content>',
                    inputs: ['containerWidthScrollTable', 'data', 'dataTableMessage', 'dataTableMessageDynamic', 'dataTest', 'depthLevel', 'dynamic', 'filterData', 'footer', 'headerSplit', 'headers', 'hiddenCurrentActions', 'hiddenHeader', 'hiddenNewRow', 'keyId', 'newItemText', 'reorder', 'resizeColumn', 'rowHeight', 'rowNumber', 'selectRows', 'sortable', 'sticky', 'stopAutoCloseEditMode'],
                    outputs: ['init', 'change', 'changeRow', 'openEditMode', 'closeEditMode', 'checkboxTableHeader', 'buttonEmptyData', 'buttonDropdownEmptyData']
                },] },
    ];
    /** @nocollapse */
    SiigoDataTableMolecule.ctorParameters = function () { return [
        { type: ChangeDetectorRef },
        { type: ElementRef },
        { type: NgZone }
    ]; };
    SiigoDataTableMolecule = __decorate([
        ProxyCmp({
            inputs: ['containerWidthScrollTable', 'data', 'dataTableMessage', 'dataTableMessageDynamic', 'dataTest', 'depthLevel', 'dynamic', 'filterData', 'footer', 'headerSplit', 'headers', 'hiddenCurrentActions', 'hiddenHeader', 'hiddenNewRow', 'keyId', 'newItemText', 'reorder', 'resizeColumn', 'rowHeight', 'rowNumber', 'selectRows', 'sortable', 'sticky', 'stopAutoCloseEditMode'],
            methods: ['setDefaultTemplate', 'setDynamicTemplate', 'selectAllRows', 'setItem', 'setCollapsibleContent', 'removeCollapsibleContent', 'toggleCollapsibleRow', 'onClickDeleteItemEvent']
        }),
        __metadata("design:paramtypes", [ChangeDetectorRef, ElementRef, NgZone])
    ], SiigoDataTableMolecule);
    return SiigoDataTableMolecule;
}());

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,missingOverride,missingReturn,unusedPrivateMembers,uselessCode} checked by tsc
 */
defineCustomElements(window);
/** @type {?} */
var DECLARATIONS = [
    // proxies
    SiigoDataTableMolecule,
    SiigoDataTableCell,
    SiigoDataTableHeaderButton,
];
var SiigoDataTableMoleculeLibraryModule = /** @class */ (function () {
    function SiigoDataTableMoleculeLibraryModule() {
    }
    SiigoDataTableMoleculeLibraryModule.decorators = [
        { type: NgModule, args: [{
                    declarations: DECLARATIONS,
                    exports: DECLARATIONS,
                    imports: [],
                    providers: [],
                },] },
    ];
    return SiigoDataTableMoleculeLibraryModule;
}());

export { SiigoDataTableCell, SiigoDataTableHeaderButton, SiigoDataTableMolecule, SiigoDataTableMoleculeLibraryModule, ProxyCmp as ɵa };
